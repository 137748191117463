import React, { ElementRef, MutableRefObject, forwardRef, useImperativeHandle } from 'react';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Box, Button, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import PhotoIcon from '@mui/icons-material/Photo';
import { useDispatch, useSelector } from 'react-redux';
import { addBadgeTotal, clearCountTotalBadge, getRooms, sendMessage, updateRoom, uploadFile } from '@/store/slices/chatSlice';
import MoodIcon from '@mui/icons-material/Mood';
import Swal from "sweetalert2";
import Image from 'next/image';
import EmojiPicker from 'emoji-picker-react';
import { ImgUrl } from '@/config';
import DOMPurify from 'isomorphic-dompurify'

interface Props {
    room: any
}

interface Room {
    roomImage: string
    lastMessage: string
    lastMessageDate: string
    roomId: string
    roomName: string
    participateId: string
    ownerId: string
    isOnline: string
    totalUnread: number
}
const InputType = (props: Props) => {

    const fileRef = React.useRef<any>(null);
    const fileVideoRef = React.useRef<any>(null);
    const ref = React.useRef<any>(null);
    const dispatch = useDispatch();
    const [attachments, setAttachments] = React.useState<File[] & any>([])
    const [attachmentVideos, setAttachmentVideos] = React.useState<File[] & any>([])
    const [visible, setVisible] = React.useState<boolean>(false)
    // const [visible, setVisible] = React.useState<boolean>(true)
    const [newMessage, setNewMessage] = React.useState<string>('');
    const [openStickers, setOpenStickers] = React.useState(false);
    const [isShowInputText, setIsShowInputText] = React.useState(true);

    const roomsRedux = useSelector(getRooms) as Room[];    

    const handleTooltipClose = () => {
        setOpenStickers(false);
    };

    const handleTooltipOpen = () => {
        setOpenStickers(true);
        setVisible(false);
    };

    const handleOpenEmoji = () => {
        setVisible(true)
        setOpenStickers(false);

    }

    const handleReadMessage = () => {      
        let room =  roomsRedux.filter(item => item.roomId == props.room.roomId)[0];                      
        dispatch(updateRoom({
            status: 'updateRoomBadge',
            roomId: props.room.roomId,
            totalUnread: 0
        }))
        dispatch(addBadgeTotal({
            num: room.totalUnread,
            act: 'minus'
        }));
        
    };


    const onTextChange = (text: string) => {
        setNewMessage(text)
    }


    const handleSendMessage = () => {

        if (attachments.length) {
            dispatch(uploadFile({
                toRoomId: props.room?.roomId,
                toParticipateId: props.room?.participateId,
                content: {
                    contentType: 'photo',
                    message: ''
                },
                attachments: attachments
            }))
            setAttachments([])
        }

        if (attachmentVideos.length) {
            dispatch(uploadFile({
                toRoomId: props.room?.roomId,
                toParticipateId: props.room?.participateId,
                content: {
                    contentType: 'video',
                    message: ''
                },
                attachments: attachmentVideos
            }))
            setAttachmentVideos([])
        }

        const _newMessage = newMessage.trim()
        const cleanDom = DOMPurify.sanitize(_newMessage)
        
        if (cleanDom) {

            dispatch(sendMessage({
                content: {
                    message: cleanDom,
                },
                toRoomId: props.room?.roomId,
                toParticipateId: props.room?.participateId
            }))
            setNewMessage('')
            setVisible(false)
        }

        setIsShowInputText(true)

    };

    const addEmojiToText = (emoji: string) => {
        setNewMessage(p => `${p}${emoji}`)
        ref.current.focus();

    }



    const onChangeFile = (event: any) => {

        event.stopPropagation();
        event.preventDefault();
        const files = event.target.files;
        if ((attachments.length + files.length) > 5) {
            Swal.fire("", "อัพโหลดได้ทีละ 5 ไฟล์เท่านั้น")
            return;
        }
        Array.from(files).map(async (file: any) => {
            attachments.push(file)
            setAttachments([...attachments])
        })


        setIsShowInputText(false)
    }

    const onChangeVideo = (event: any) => {

        event.stopPropagation();
        event.preventDefault();
        const files = event.target.files;
        if ((attachmentVideos.length + files.length) > 5) {
            Swal.fire("", "อัพโหลดได้ทีละ 5 ไฟล์เท่านั้น")
            return;
        }
        Array.from(files).map(async (file: any) => {
            attachmentVideos.push(file)
            setAttachmentVideos([...attachmentVideos])
        })


        setIsShowInputText(false)
    }


    const removeAttachment = (index: number) => {
        attachments.splice(index, 1)
        setAttachments([...attachments])
        if (attachments.length >= 1 || attachmentVideos.length >= 1) {
            setIsShowInputText(false)
        } else {
            setIsShowInputText(true)
        }
    }

    const removeAttachmentVideo = (index: number) => {
        attachmentVideos.splice(index, 1)
        setAttachmentVideos([...attachmentVideos])
        if (attachments.length >= 1 || attachmentVideos.length >= 1) {
            setIsShowInputText(false)
        } else {
            setIsShowInputText(true)
        }
    }


    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip {...props} classes={{ popper: className }} />
        </ClickAwayListener>
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#242526',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #242526',
            borderRadius: 10
        },
    }));

    const sendSticker = (stickerUrl: string) => {

        const stickerId = stickerUrl;

        dispatch(sendMessage({
            content: {
                contentType: 'sticker',
                message: '',
                sticker: {
                    stickerId: stickerId,
                    stickerUrl: stickerId
                }
            },
            toRoomId: props.room?.roomId,
            toParticipateId: props.room?.participateId
        }))
        handleTooltipClose();
    }


    return (
        <>

            <div style={{
                display: 'flex', flexDirection: 'row', marginBottom: 10, alignItems: 'center' 
            }}>
                {
                    (attachments.length || attachmentVideos.length) >= 1 ?
                        <div
                            onClick={() => fileRef.current.click()}
                            style={{
                                display: 'flex', alignItems: 'center', cursor: 'pointer', justifyContent: 'center',
                                border: '1px solid #ccc',
                                marginRight: 7,
                                width: 50, height: 50, borderRadius: 10, borderWidth: 0.5, borderColor: 'black'
                            }}>
                            <span style={{ color: '#24bdbf', fontSize: 18 }}>+</span>
                        </div>
                        :
                        null
                }
                {
                    attachments?.map((attachment: any, index: number) => {
                        return (
                            <div key={index} style={{ position: 'relative', width: 50, height: 50, marginRight: 7 }}>
                                {
                                    ['video/mp4'].includes(attachment.type) ?
                                        <img
                                            src={'https://icons.veryicon.com/png/o/miscellaneous/food-time/play-video-1.png'}
                                            style={{ width: 50, height: 50, borderRadius: 10 }} />
                                        :
                                        <img
                                            src={window.URL.createObjectURL(attachment)}
                                            style={{ width: 50, height: 50, borderRadius: 10, objectFit: 'contain' }} />
                                }

                                <IconButton onClick={() => removeAttachment(index)} style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0 }}>
                                    <DeleteIcon style={{ color: 'red' }}></DeleteIcon>
                                </IconButton>

                            </div>
                        )
                    })
                }

                {
                    attachmentVideos?.map((attachment: any, index: number) => {
                        return (
                            <div key={index} style={{ position: 'relative', width: 50, height: 50, marginRight: 7 }}>
                                {
                                    ['video/mp4'].includes(attachment.type) ?
                                        <img
                                            src={'https://icons.veryicon.com/png/o/miscellaneous/food-time/play-video-1.png'}
                                            style={{ width: 50, height: 50, borderRadius: 10 }} />
                                        :
                                        <img
                                            src={window.URL.createObjectURL(attachment)}
                                            style={{ width: 50, height: 50, borderRadius: 10 }} />
                                }

                                <IconButton onClick={() => removeAttachmentVideo(index)} style={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0 }}>
                                    <DeleteIcon style={{ color: 'red' }}></DeleteIcon>
                                </IconButton>

                            </div>
                        )
                    })
                }
            </div>
            {
                isShowInputText ?
                    <TextareaAutosize
                        ref={ref}
                        minRows={3}
                        maxRows={6}
                        placeholder="พิมพ์ข้อความ"
                        value={newMessage}
                        onClick={()=>{
                            handleReadMessage();
                        }}
                        onKeyDown={(e) => {
                            if (e.key == 'Enter') {
                                handleSendMessage()
                                e.preventDefault();
                            }
                        }}
                        onChange={(e) => {
                            onTextChange(e.target.value)

                        }}
                        style={{
                            resize: 'none',
                            border: 'none',
                            background: 'none',
                            padding: 0,
                            outline: 'none',
                            borderBottom: '1px solid rgb(0 0 0 / 14%)',
                            marginBottom: '12px',
                            maxHeight: '50px',
                            // position: "absolute",
                            // bottom: 0,
                            // width: '100%',
                            // display: 'inline-block'
                            height: "100% !important"
                        }}
                    />
                    :
                    null
            }


            {/* Icons for attachments, stickers, photos, and videos */}
            <Box 
                // display="flex" justifyContent="space-between" flexDirection="row"
            >
                <div>
                    {/* ตัวนี้คือ อิโมจิ */}
                    {/* <span style={{ position: 'relative' }}>
                        {
                            visible ?
                                <div style={{
                                    position: 'absolute',
                                    zIndex: 9999,
                                    top: -410
                                }}>
                                    <EmojiPicker
                                        previewConfig={{
                                            defaultEmoji: '1f60a',
                                            defaultCaption: '',
                                            showPreview: true
                                        }}
                                        onEmojiClick={(e: any) => {
                                            console.log("e", e)
                                            if (e.isCustom) {
                                                addEmojiToText(`:${e.emoji}:`)
                                            } else {
                                                addEmojiToText(e.emoji)
                                            }

                                        }}
                                        height={350}
                                        width="23em"
                                    ></EmojiPicker>
                                </div>
                                :
                                null
                        }
                        <IconButton
                            onClick={handleOpenEmoji}
                            // onClick={() => {
                            //     setVisible(!visible)
                            // }}
                            disabled={!isShowInputText}
                        >

                            <img src="/assets/images/chat/icon_emo_chat.svg" width={24} height={24} />
                        </IconButton>
                    </span> */}


                    {/* ตัวนี้คือสติ๊กเกอร์ */}
                    <HtmlTooltip
                        arrow
                        PopperProps={{
                            disablePortal: true,
                        }}
                        onClose={handleTooltipClose}
                        open={openStickers}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={
                            <React.Fragment>
                                {
                                    [1].map((item, index: number) => {
                                        return (
                                            <Image key={index} src={`${ImgUrl.pathImg}chat/sticker/sticker-01.svg`} alt="stickers1" width={35} height={35} style={{ cursor: 'pointer' }}></Image>
                                        )
                                    })
                                }
                                <Divider />
                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                    {Array.from(Array(1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12)).map((item, index) => (
                                        <Grid item xs={2} sm={4} md={4} key={index}>
                                            <Image
                                                onClick={() => sendSticker(`${ImgUrl.pathImg}chat/sticker/sticker-${String(item).padStart(2, '0')}.svg`)}
                                                key={index}
                                                src={`${ImgUrl.pathImg}chat/sticker/sticker-${String(item).padStart(2, '0')}.svg`}
                                                alt="stickers1"
                                                width={0}
                                                height={0}
                                                sizes='100vw'
                                                style={{ width: '100%', height: 'auto', cursor: 'pointer' }} // optional
                                            ></Image>
                                        </Grid>
                                    ))}
                                </Grid>

                            </React.Fragment>
                        }
                    >

                        <IconButton
                            onClick={handleTooltipOpen}
                            disabled={!isShowInputText}
                        >
                            <img src="/assets/images/chat/icon_sticker_chat.svg" width={24} height={24} />
                        </IconButton>
                    </HtmlTooltip>



                    <input ref={fileRef}
                        type="file"
                        value={""}
                        onChange={onChangeFile}
                        style={{ display: 'none' }}
                        accept="image/*"
                        multiple />

                    <IconButton onClick={() => {
                        fileRef.current.click()
                        if (visible) {
                            setVisible(false)
                        }

                    }}>
                        <PhotoIcon />
                    </IconButton>

                    <input ref={fileVideoRef}
                        type="file"
                        value={""}
                        onChange={onChangeVideo}
                        style={{ display: 'none' }}
                        accept="video/*"
                        multiple />

                    <IconButton onClick={() => {
                        fileVideoRef.current.click()
                        if (visible) {
                            setVisible(false)
                        }
                    }}>
                        <VideoFileIcon />
                    </IconButton>

                </div >
                <IconButton onClick={() => handleSendMessage()} style={{ position: "absolute", right: "15px", bottom: "15px" }}>
                    <SendIcon style={{ color: "#DB5A00" }} />
                </IconButton>
            </Box >
        </>
    )
}

export default InputType